export const getHubspotTrackingScript = () => {
  if (process.env.APP_ENV !== 'prod') {
    return `<!-- Start of HubSpot Embed Code -->
    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/23123445.js"></script>
    <!-- End of HubSpot Embed Code -->`;
  } else {
    return `<!-- Start of HubSpot Embed Code -->
    <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/1545457.js"></script>
    <!-- End of HubSpot Embed Code -->`;
  }
};
